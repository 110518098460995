import React from "react";
import './style.css';

const NotFound: React.FC = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 536 235">
      <g id="_4" data-name="4" className="cls-1">
        <g className="cls-1">
          <path className="cls-2" d="M185.75,177H148.14v48.64H120.7V177h-110v-19.8L111.94,27.68h30.54l-97,125h76.08v-43h26.59v43h37.61Z"/>
        </g>
      </g>
      <g id="_4-copy" data-name="4-copy" className="cls-1">
        <g className="cls-1">
          <path className="cls-2" d="M532.07,177H494.46v48.64H467V177H357v-19.8L458.25,27.68H488.8l-97,125h76.08v-43h26.59v43h37.61Z"/>
        </g>
      </g>
      <g id="Group-10">
        <path id="Fill-37" className="cls-3" d="M356.18,80.35s-.5-11.23-12.78-11.23S320.09,79.33,318.08,90s-3.25,26.28,4.77,30.1,16-6.37,21.3-14,12-17.6,12-25.76"/>
        <path id="Fill-39" className="cls-3" d="M311.44,24.91S303.25,10,294.6,14.27s-13.78,23.09-17.83,33.85-5.42,36.35,11.37,39.32,27-6.66,29.46-23-.38-29.29-6.16-39.5"/>
        <path id="Fill-41" className="cls-3" d="M266,21.55S262.81,6.69,252.11,7.92s-28.61,14.5-30.32,28.82S222.9,77.49,242.41,81s29.38-15.46,27.82-28.7S266,21.55,266,21.55"/>
        <path id="Fill-43" className="cls-3" d="M207,56.35s-9.39-13.15-15.28-9.07-10.53,18.65-3.79,33.88,21.95,16.9,28.26,11.68S220.6,75,217.88,69.67,207,56.35,207,56.35"/>
        <path id="Fill-45" className="cls-3" d="M341.45,126.22s-17,9.37-26.28-.38-28.8-33.23-56.59-36.4c-36.38-4.15-56.86,17.29-60,35.81-1.55,9.26-1.36,16.55,4.94,23.76,10.94,12.51,23.23,10,31.91,13C250,167,251,175.92,253.91,181.17c6.29,11.24,6.84,36.5,14.3,45.78,4.25,5.29,16.52,1.3,22-5.57C305.51,202,305,189.43,313.59,173.75c4.21-7.71,14.41-18.62,19.87-24.19,16.56-16.9,19.83-29.74,8.53-23.64Z"/>
      </g>
    </svg>
  )
}

export default NotFound;
