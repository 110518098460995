import React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/template/Layout"
import { NotFoundPageProp  } from '../entities/pagepart'
import { CurrentView  } from '../entities/templates'
import NotFoundIcon from "../components/svg/404/404";
import useSetPagePartGlobal from '../components/hooks/useSetPagePartGlobal';
import PagePartRenderer from '../templates/page/PagePartRenderer';
import Wrapper from '../components/template/ContainerMax';
import Text from "../components/atoms/Text";

const subNavtitle = {
  de: 'Seite nicht gefunden', en: 'Page Not Found'
}

const notFoundText = {
  en: 'Looks like the page went on Safari',
  de: 'Anscheinend ging die Seite auf Safari'
};
const notFoundTextSub = {
  en: 'Select a Country Below to book your own holiday',
  de: 'Wählen Sie unten ein Land aus, um Ihren eigenen Urlaub zu buchen'
};

type Env = 'production' | 'development'
const env: Env = process.env.GATSBY_SANITY_STUDIO_API_DATASET as Env  || 'production';

const NotFoundPage: React.FC<NotFoundPageProp> = ({ data }) => {
  const pageContext = {
    locale: 'de',
    siteConfig: data?.allSanitySiteConfiguration?.edges?.find((item => item?.node?.environment === env))?.node,
  } as any

  useSetPagePartGlobal({ pageContext, locale: pageContext?.locale,
    currentView: CurrentView.pagePartView, 
    subNavigationItem: data?.pageData?.subnavigation?.items,
    subNavigationTitle: subNavtitle
 });

 const pageParts = [data?.pageData?.pageParts.filter(item => item._key)?.[0] ] as any;

  return (
    <Layout seoTitle="Access Africa" hideHero={true} hideSubMenu={true}>
      <Wrapper>
        <div className="p-16">
          <div className="">
            <NotFoundIcon />

            <Text type="H2" className="text-granite-green mt-8 text-center">{notFoundText?.[pageContext?.locale as 'de' | 'en']}</Text>
          </div>

          <div className="mt-10">
            <Text type="H3" className="text-granite-green text-center">{notFoundTextSub?.[pageContext?.locale as 'de' | 'en']}</Text>
          </div>
        </div>
      </Wrapper>
      {pageParts && pageParts?.map((part: any, i: number) => <PagePartRenderer part={ part } key={i} id={i} 
        showSubMenu={!!data?.pageData?.subnavigation} />)}
    </Layout>
  )
}

export default NotFoundPage;

export const query = graphql`
  query NotFoundAndSiteConfig {

    allSanitySiteConfiguration {
      edges {
        node {
          _id
          environment
          address
          copyright
          email
          facebook
          linkedin
          siteTitle
          telephone
          title
          twitter
          youtube
          _id
          _key
          _type
          navigation {
            _id
            menu: items {
              items {
                name {
                  _type
                  de
    
                }
                link {
                  ... on SanityCountry {
                    typename: __typename
                    id
                    slug {
                      de {
                        current
                      }
                    }
                  }
                  ... on SanityPage {
                    typename: __typename
                    id
                    slug {
                      de {
                        current
                      }
                    }
                  }
                }
                navanchor
              }
              name {
                de
  
                _type
              }
            }
            footerItems {
              _key
              name {
                de
  
              }
              items {
                name {
                  de
    
                }
                link {
                  ... on SanityCountry {
                    id
                    slug {
                      de {
                        current
                      }
                    }
                  }
                  ... on SanityPage {
                    id
                    slug {
                      de {
                        current
                      }
                    }
                  }
                }
              }
              link {
                ... on SanityCountry {
                  id
                  slug {
                    de {
                      current
                    }
                  }
                }
                ... on SanityPage {
                  id
                  slug {
                    de {
                      current
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    pageData: sanityPage(_id: {eq: "8a6944c2-464a-49eb-a31c-5ed759388233"}) {
      __typename
      breadcrumbEnabled
      breadcrumbTitle {
        de

      }
      
      subnavigation {
        title
        items {
          name {
            _type
            de

          }
          link {
            ... on SanityCountry {
              typename: __typename
              id
              slug {
                de {
                  current
                }
              }
            }
            ... on SanityPage {
              typename: __typename
              id
              slug {
                de {
                  current
                }
              }
            }
          }
        }
      }

      pageParts {
  

        ... on SanityCardCollection {
          _key
          _type
          part_style: partStyle
          navanchor
          cardCollectionStyle 
          heading {
            de

          }
          intro: _rawIntro(resolveReferences: {maxDepth: 10})
          button: _rawButton(resolveReferences: {maxDepth: 2})
          cards {
            _id
            image: _rawImage(resolveReferences: {maxDepth: 10})
            body: _rawBody(resolveReferences: {maxDepth: 10})
            subheading {
              de

            }
            cardNoTravelBag
            title
            heading {
              de

            }
            cardLink: _rawCardLinks(resolveReferences: {maxDepth: 2})
            button {
              buttonText {
                de
  
              }
              buttonHref
              buttonLink {
                ... on SanityPage {
                  id
                  __typename
                  slug {
                    de {
                      current
                    }
  
                  }
                }
                ... on SanityCountry {
                  id
                  __typename
                  slug {
                    de {
                      current
                    }
  
                  }
                }
              }
            }
          }
        }

      }
    }
  }
`
